@import "../../../theme/index.module.scss";

.TechnicalMenu {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 3em 0;
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      position: relative;
      padding: 0 0.65em;
    }
  }
}

.Last {
  color: $white;
  position: relative;
  display: block;
  padding: vr(0.5);
  &:after {
    display: none;
  }
}

.TechnicalLink {
  color: $white;
  display: block;
  
  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 100%;
    right: -0.2em;
    bottom: 0.5em;
    display: flex;

    @include bp($medium) {
      right: -0.4em;
    }
  }
}

.TechnicalLinkFirst {
  composes: TechnicalLink;
  border-top: none;
}

@include bp($small) {
  .TechnicalMenu {
    & ul {
      max-width: 350px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: nowrap;
      & li {
        display: inline-block;
        width: auto;
      }
    }
  }

  .TechnicalLink {
    display: inline-block;
    color: $white;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .TechnicalMenu {
    margin: 3.6em 0;
  }
}


